import {maybeInstallMembersArea} from '@wix/members-area-integration-kit'
import type {EditorSDK, TPAComponentType} from '@wix/platform-editor-sdk'
import {
  EVENTS_APP_ID,
  EVENT_LIST_SECTION_ID,
  GROUPS_APP_ID,
  LIVE_VIDEO_APP_ID,
  PAID_PLANS_APP_ID,
  SCHEDULE_SECTION_ID,
} from '@wix/wix-events-commons-statics'
import {Interactions} from '../editor.app'
import {WithApproval} from './services/concurrent-editing'
import {TFunction} from './services/translations'
import {FlowApi} from './types'

export const handleAppInstalled = async ({
  appDefinitionId,
  disableMembersInstall,
  withApproval,
  flowApi,
}: HandleAppInstalledParams) => {
  switch (appDefinitionId) {
    case EVENTS_APP_ID:
      try {
        if (!disableMembersInstall) {
          flowApi.fedops.interactionStarted(Interactions.INSTALL_MEMBERS_AREA)
          await withApproval(() => maybeInstallMembersArea())
          flowApi.fedops.interactionEnded(Interactions.INSTALL_MEMBERS_AREA)
        }
      } catch (e) {
        console.error(e)
      }

      return
    default:
      return
  }
}

export const isEventsInstalled = ({editorSdk, token, forcedPageInstall}: IsEventsInstalledParams) => {
  if (forcedPageInstall) {
    // is platform app installed
    return editorSdk.application.isApplicationInstalled(token, {
      appDefinitionId: EVENTS_APP_ID,
    })
  } else {
    // is widget installed
    return editorSdk.tpa.isApplicationInstalled(token, {
      appDefinitionId: EVENTS_APP_ID,
    })
  }
}

const addPaidPlans = ({editorSdk, token}: AddParams) =>
  editorSdk.document.tpa.add.application(token, {appDefinitionId: PAID_PLANS_APP_ID})

const addLiveVideo = ({editorSdk, token}: AddParams) =>
  editorSdk.document.tpa.add.application(token, {appDefinitionId: LIVE_VIDEO_APP_ID})

const addGroups = ({editorSdk, token}: AddParams) =>
  editorSdk.document.tpa.add.application(token, {appDefinitionId: GROUPS_APP_ID})

export const handleMigrate = ({payload, withApproval, editorSdk, token, t, forcedPageInstall}: HandleMigrateParams) => {
  if (payload?.addPaidPlans) {
    return withApproval(() => addPaidPlans({editorSdk, token}))
  }

  if (payload?.addEventListPage) {
    return withApproval(() =>
      installPage({
        editorSdk,
        token,
        forcedPageInstall,
        pageId: EVENT_LIST_SECTION_ID,
        title: t('pagesPanelEventsListTitle'),
        isHidden: false,
      }),
    )
  }

  if (payload?.addSchedulePage) {
    return withApproval(() =>
      installPage({
        editorSdk,
        token,
        forcedPageInstall,
        pageId: SCHEDULE_SECTION_ID,
        title: t('pagesPanelEventsScheduleTitle'),
      }),
    )
  }

  if (payload?.addLiveVideo) {
    return withApproval(() => addLiveVideo({editorSdk, token}))
  }

  if (payload?.addGroups) {
    return withApproval(() => addGroups({editorSdk, token}))
  }

  return
}

export const installPage = async ({
  editorSdk,
  token,
  pageId,
  title,
  isHidden = true,
  forcedPageInstall,
}: InstallPageParams) => {
  // This is needed because Editor script runs even if Events are uninstalled...
  // Not having this check makes it impossible to uninstall Events

  const eventsInstalled = await isEventsInstalled({editorSdk, token, forcedPageInstall})
  const pageInstalled = await editorSdk.tpa.isAppSectionInstalled(token, {
    sectionId: pageId,
    appDefinitionId: EVENTS_APP_ID,
  })

  if (eventsInstalled && !pageInstalled) {
    try {
      return editorSdk.tpa.add.component(token, {
        appDefinitionId: EVENTS_APP_ID,
        componentType: 'PAGE' as TPAComponentType.Page,
        page: {
          pageId,
          title,
          isHidden,
          shouldNavigate: false,
        },
      })
    } catch (e) {
      console.log('Prevented exception from breaking editor script!', e)
      return null
    }
  }
}

interface GenericParams {
  editorSdk: EditorSDK
  token: string
  forcedPageInstall: boolean
}

interface HandleAppInstalledParams {
  appDefinitionId: string
  disableMembersInstall: boolean
  withApproval: WithApproval
  flowApi: FlowApi
}

interface HandleMigrateParams extends GenericParams {
  payload: any
  withApproval: WithApproval
  t: TFunction
}

interface IsEventsInstalledParams extends GenericParams {}

interface InstallPageParams extends GenericParams {
  pageId: string
  title?: string
  isHidden?: boolean
}

interface AddParams extends Omit<GenericParams, 'forcedPageInstall'> {}
